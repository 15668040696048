<template>
    <v-container class="mt-3 px-16">
        <div class="text-h3 mb-6 font-weight-bold">
            Unsub Privacy Policy
        </div>
        <p>Last Revised: August 26, 2022</p>
        <p class="pt-6">
            Unsub is committed to processing your personal information in a fair and lawful manner. We have developed this Privacy Statement to inform you about how we handle personal information and about our privacy practices including for our website and any other Unsub and our other Unsub products and services (the “Covered Products and Services”).
        </p>

        <div class="text-h4 pt-4 font-weight-bold">
            I. General Policies on How and What We Use and How We Protect Your Data
        </div>
        <v-card flat class="pa-6">
            <div class="text-h5 mt-3 mb-2 font-weight-bold">
                A. What Information We Gather And From Whom
            </div>
            <p>
                We may collect, use, store and transfer different kinds of personal information about you. We collect personal information directly from you and indirectly about you as described below. We may collect the following types of personal information from or about you, which we have grouped into categories as follows:
            </p>
            <ul>
                <li>Identity Data: includes first name, last name, job title, and company name.</li>
                <li>Contact Data: includes billing address, email address, and telephone numbers.</li>
                <li>Transaction Data: includes details about payments to and from you and other details of products and services you have purchased from us.</li>
                <li>Technical Data: includes internet protocol (“IP”) address, cookies, HTTP headers, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access Unsub.</li>
                <li>Profile Data: includes your email and password, purchases or orders made by you, feedback, survey responses, and any unsolicited personal information not otherwise covered by this Privacy Statement that you choose to submit in an inquiry or webchat, or otherwise on Unsub or in person to a Unsub representative at an event.</li>
                <li>Usage Data: includes information about how you use Unsub, and/or our products and services.</li>
                <li>Marketing and Communications Data: includes your communication preferences in receiving marketing from us.</li>
            </ul>
        </v-card>
        <v-card flat class="pa-6">
            <p>
                We may collect the categories of personal information described above either directly or indirectly from different sources, including the following:
            </p>
            <ul>
                <li>Our business partners.</li>
                <li>Our customers.</li>
                <li>You, when you interact with us or in connection with Covered Products and Services.</li>
                <li>Although we do not manage any internet cookies, analytics programs, pixels, web beacons, and other technologies, our partners, Intercom may. As we explain in Section D, we use Intercom’s services to help us operate certain functions of our platform effectively and efficiently.</li>
            </ul>
        </v-card>
        <v-card flat class="pa-6">
            <p>
                Please note that Unsub may contain links to other unaffiliated third-party websites. Unsub is not responsible for the privacy practices, privacy statements, or content contained in or regarding these other unaffiliated third-party websites that do not include this Privacy Statement. You should consult the privacy statements associated with these unaffiliated third-party websites should you decide to visit them. 
            </p>
            <p>
                You should be aware that we use Google Group to host our Unsub-Discuss page. Any personal information you submit there will be stored on Google Group’s servers and can be read, collected, or used by other users of these blog forums, and could be used to send you unsolicited messages. Unsub is not responsible for the actions that other users of the Unsub Discuss page take in relation to personal information you choose to submit in the forums. You are also responsible for using these forums in a manner consistent with our Terms of Service or other terms and conditions set forth on the relevant forum website.
            </p>
        </v-card>
        

        <v-card flat class="pa-6">
            <div class="text-h5 mt-3 mb-2 font-weight-bold">
                B. How We Use Personal Information
            </div>
            <p>
                We will only use your personal information in a manner that is consistent with applicable laws. Most commonly, we will use the categories of personal information identified above for the following business purposes:
            </p>
            <ul>
                <li>For the provision of our Covered Products and Services or the operation of our business or a third party’s business. All of our employees and contractors are required to follow our data privacy and security policies when handling personal information.</li>
                <li>Where we need to perform a contract that we have entered into with you, or in order to take steps at your request prior to entering into a contract.</li>
                <li>To protect the security and integrity of our business, or our Covered Products and services.</li>
                <li>Where we need to protect your interests (or someone else's interests).</li>
                <li>Where it is needed in the public interest or for official purposes.</li>
                <li>For compliance with legal obligations and protection of Unsub and others. Specifically, we may be required to use and retain personal information for legal and compliance reasons, such as the prevention, detection, or investigation of a crime; loss prevention; or fraud. We may also use personal information to meet our internal and external audit requirements, and as we otherwise believe to be necessary or appropriate: (i) under applicable law, which may include laws outside your country of residence; (ii) to respond to requests from courts, law enforcement agencies, regulatory agencies, and other public and government authorities, which may include such authorities outside your country of residence; (iii) to enforce or apply our contractual rights; and (iv) to otherwise protect our rights, privacy, safety, or property, or those of other persons.</li>
                <li>For marketing purposes. Specifically, we may use your personal information (including cookies – for more information on how we use cookies, please see Section I.D below) to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services, and offers may be relevant for you (we call this marketing). You may receive marketing communications from us if you have purchased products or services from us, we have identified similar products or services that may be relevant for you and, in each case, you have not opted out of receiving that marketing. We will get your express opt-in consent before we market products or services to you that are not similar to products or services you have purchased from us before, or if we share your personal information with any company outside the Unsub group of companies for marketing purposes. You can ask us or third parties to stop sending you marketing messages at any time. If you receive a marketing communication from us, the communication will contain an option to opt-out of further communications. If you receive a marketing communication from one of our business partners, or from social media providers, you should opt out with that entity directly. You can also contact us at any time to opt out using the details given at the end of this Privacy Statement.</li>
            </ul>
        </v-card>
        <v-card flat class="pa-6">
            <p>California residents, please review Section III for additional disclosures about how we may use your personal information.</p>
            <p>We will only use your personal information for the purposes for which we collected it, including where we reasonably consider that we need to use it for a reason that is compatible with the original purpose. If we need to use your personal information for an unrelated purpose, we will notify you as required by law.</p>
            <p>Please note that we may process your personal information without your knowledge or consent where this is required or permitted by law.</p>
        </v-card>
        
       
        <v-card flat class="pa-6">
            <div class="text-h5 mt-3 mb-2 font-weight-bold">
                C. When, Why, And With Whom We Share Personal Information
            </div>
            <p>
                We may disclose the following categories of personal information to entities other than Unsub for any the business purposes set out in above. When we disclose personal information, we do so in accordance with our data privacy and security requirements.
            </p>
            <ul>
                <li>Identity Data: includes first name, last name, job title, and company name.</li>
                <li>Contact Data: includes billing address, email address, and telephone numbers.</li>
                <li>Transaction Data: includes details about payments to and from you and other details of products and services you have purchased from us or our business partners.</li>
                <li>Technical Data: includes internet protocol ("IP") address, cookies, java script, web beacons, HTTP headers, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access Unsub.</li>
                <li>Profile Data: includes your email and password, purchases or orders made by you, feedback, survey responses, and any unsolicited personal information not otherwise covered by this Privacy Statement that you choose to submit in an inquiry or webchat, on a blog forum or otherwise on our Unsub or in person to a Unsub representative at an event.</li>
                <li>Usage Data: includes information about how you use Unsub, and/or our products and services.</li>
                <li>Marketing and Communications Data: includes your preferences in receiving marketing from us.</li>
            </ul>
        </v-card>
        <v-card flat class="pa-6">
            <p>Below are the categories of entities to whom we may disclose personal information and why.</p>
            <ul>
                <li>Business partners: We may provide your personal information to a Unsub business partner. Please be assured that these Unsub business partners have agreed to ensure the privacy and security of any transferred information and may only use the shared information to send you information about products or services about which you asked, or for sending updates or providing services on our behalf.</li>
                <li>Service providers: We partner with, and are supported by, service providers around the world. Personal information will be made available to these parties only when necessary to fulfill the services they provide to us, such as software, system, and platform support; direct marketing services; cloud hosting services; advertising; data analytics; order fulfillment and delivery; and services to assist us in managing and responding to consumer privacy and other requests. Our service providers are not permitted to share or use personal information we make available to them for any purpose other than to provide services to us.</li>
                <li>Third parties for legal reasons. We will share personal information where we believe it is required: (i) in order to comply with our legal and contractual obligations; (ii) to protect Unsub and others; and (iii) in the context of a business transition, such as a merger or acquisition.</li>
                <li>Government entities. We will share personal information for information sharing with authorized agents of the government upon request pursuant to a valid legal order.</li>
            </ul>
        </v-card>
        <v-card flat class="pa-6">
            <p>We do not sell personal information to third parties.</p>
            <p>California residents, please review Section 3 for additional disclosures about how we may disclose your personal information.</p>
        </v-card>


        <v-card flat class="pa-6">
            <div class="text-h5 mt-3 mb-2 font-weight-bold" id="intercom" style="padding-top: 80px;">
                D. Use Of Cookies, Other Tracking Technologies And Gathering Of Statistical Data
            </div>
            <p>
                Although we do not use cookies, we partner with one primary company that leverages cookie technology.
            </p>
            <ul>
                <li>Use of Intercom Services: We use third-party analytics services to help understand your usage of our services. In particular, we provide a limited amount of your information (such as sign-up date and some personal information like your email address) to Intercom, Inc. (“Intercom”) and utilize Intercom to collect data for analytics purposes when you visit our website or use our product. As a data processor acting on our behalf, Intercom analyzes your use of our website and/or product and tracks our relationship by way of cookies and similar technologies so that we can improve our service to you. For more information on Intercom's use of cookies, please visit <a href="https://www.intercom.com/terms-and-policies#cookie-policy">https://www.intercom.com/terms-and-policies#cookie-policy</a>. We may also use Intercom as a medium for communications, either through email, or through messages within our product(s). The Intercom Messenger Apps and Apps in Inbox products may also provide you with access to other third-party applications such as Stripe. You should consult these third parties' privacy notices for further information on their use of your personal data. As part of our service agreements, Intercom collects publicly available contact and social information related to you, such as your email address, gender, company, job title, photos, website URLs, social network handles and physical addresses, to enhance your user experience. For more information on the privacy practices of Intercom, please visit: <a href="https://www.intercom.com/terms-and-policies#privacy">https://www.intercom.com/terms-and-policies#privacy</a>. Intercom’s services are governed by Intercom’s terms of use which can be found at <a href="https://www.intercom.com/terms-and-policies#terms">https://www.intercom.com/terms-and-policies#terms</a>. If you would like to opt out of having this information collected by or submitted to Intercom, please contact us.</li>
            </ul>
        </v-card>
        <v-card flat class="pa-6">
            <p>You can decide whether or not to accept cookies through your internet browser’s settings. Most browsers have an option for turning off the cookie feature, which will prevent your browser from accepting new cookies, as well as (depending upon the sophistication of your browser software) allow you to decide on acceptance of each new cookie in a variety of ways. You can also delete all cookies that are already on your computer. If you do this, however, you may have to manually adjust some preferences every time you visit a site and platform and functionalities may not work. To explore what cookie settings are available to you, look in the “preferences” or “options” section of your browser’s menu. To find out more information about cookies, including information about how to manage and delete cookies, please visit <a href="https://ico.org.uk/for-the-public/online/cookies/">https://ico.org.uk/for-the-public/online/cookies/</a> or <a href="https://www.allaboutcookies.org/">https://www.allaboutcookies.org/</a>.</p>
        </v-card>
        <v-card flat class="pa-6">
            <div class="text-h6">
                Do Not Track
            </div>
            <p>We do not respond to Do Not Track signals due to a lack of industry standard.</p>
        </v-card>


        <v-card flat class="pa-6">
            <div class="text-h5 mt-3 mb-2 font-weight-bold">
                E. Our Security Procedures
            </div>
            <p>
                We consider the protection of all personal information we receive offline and online from our platforms’ visitors and subscribers as critical to our corporate mission. We have security measures in place to protect against the loss, misuse, and alteration of any personal information we receive from you. As with any transmission over the internet, however, there is always some element of risk involved in sending personal information.
            </p>
        </v-card>


        <v-card flat class="pa-6">
            <div class="text-h5 mt-3 mb-2 font-weight-bold">
                F. Changes To This Privacy Statement
            </div>
            <p>
                It is important that you check back often for updates to this Privacy Statement. If a material change is made to this Privacy Statement and/or the way we use our customers' personal information, we will post a prominent notice on the as a pop-up notification on our landing page and/or by means of other methods of contact such as email.
            </p>
        </v-card>


        <v-card flat class="pa-6">
            <div class="text-h5 mt-3 mb-2 font-weight-bold">
                G. Contact Unsub
            </div>
            <p>
                If you have questions about this Privacy Statement, you may contact us in the following ways:
                <ul>
                    <li>You may email us at: <a href="mailto:support@unsub.org">support@unsub.org</a>. Please specify "Privacy Statement" in the subject line of your email.</li>
                    <li>You may call us at: 707-889-3263</li>
                    <li>You may write to us at: 500 Westover Dr #8234 Sanford, NC 27330-8941</li>
                </ul>
            </p>
        </v-card>


        <div class="text-h4 pt-4 font-weight-bold">
            II. The General Data Protection Regulation (GDPR) Practices
        </div>
        <v-card flat class="pa-6">
            <div class="text-h5 mt-3 mb-2 font-weight-bold">
                A. The Information We Gather
            </div>
            <p>
                The information that we collect and process is described in Section I.A above. Pursuant to GDPR, we will explain the legal basis which allows us to do so.
            </p>
            <p>
                We will only collect or process sensitive personal information on the basis that: (i) it has manifestly been made public by you; or (ii) the processing is necessary: (a) in relation to legal claims; (b) for reasons of substantial public interest; or (c) where we need to carry out certain legal obligations and/or exercise certain rights relating either to or you. We do not collect any information about criminal convictions and offenses.
            </p>
        </v-card>


        <v-card flat class="pa-6">
            <div class="text-h5 mt-3 mb-2 font-weight-bold">
                B. Purposes For Which We May Use Your Personal Information
            </div>
            <p>
                An explanation of how we may use your personal information is described in Section I.B above. If you are located in the EU, you have the right to know what legal basis we rely upon to process your personal information. The chart below describes all the ways in which we plan to use your personal information and the legal bases we rely on to do so. We have also identified what our legitimate interests are where appropriate.
            </p>

            <v-simple-table>
                <thead>
                    <tr>
                        <th class="text-left">
                            Purposes for which we will process the information
                        </th>
                        <th class="text-left">
                            Legal Basis for the processing
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>To deliver services and features desired by you.</td>
                        <td>It is necessary for us to process your personal data in order to deliver the services and process transactions according to the applicable contract between us.</td>
                    </tr>
                    <tr>
                        <td>To improve our services to you.</td>
                        <td>It is necessary for us to process your personal data in order to improve our services to you according to the applicable contract between us.</td>
                    </tr>
                    <tr>
                        <td>To respond to your inquiries.</td>
                        <td>It is necessary for us to process your personal data in this manner to respond to your inquiries according to the applicable contract between us.</td>
                    </tr>
                    <tr>
                        <td>To fulfill your request for products or services.</td>  
                        <td>It is necessary for us to process your personal data to fulfill your request for products or services according to the applicable contract between us.</td>
                    </tr>
                    <tr>
                        <td>To provide you with updates and other information regarding the Services.</td>
                        <td>It is necessary for us to process your personal data to provide you with updates and other information regarding the Services according to the applicable contract between us.</td>
                    </tr>
                    <tr>
                        <td>To share your information with the company that employed you, engaged you, or authorized you to access and use the Services.</td>
                        <td>It is in the legitimate interests of the company that employed you, engaged you, or authorized you to access and use the Services. We consider this use to be proportionate and will not be prejudicial or detrimental to you.</td>
                    </tr>
                    <tr>
                        <td>To analyze statistically site usage and to customize our site's content, layout and services.</td>
                        <td>It is in our legitimate interests to process your personal data to analyze our site usage and to customize our site's content, layout and services. We consider this use to be proportionate and will not be prejudicial or detrimental to you.</td>
                    </tr>
                    <tr>
                        <td>To enable our family of companies and affiliates and with trusted business partners that we do business with who may have content and offers of interest to you.</td>
                        <td>It is in our legitimate interests to process your personal data to enable our family of companies and affiliates who may have content of interest to you. We process your personal data to enable our family of companies and affiliates and trusted business partners to send you offers and market to you only if you have consented to these activities.</td>
                    </tr>
                    <tr>
                        <td>To enable our agents and service providers to perform certain activities on our behalf.</td>
                        <td>It is necessary for us to process your personal data in this manner in order to deliver the services and process transactions according to the applicable contract between us. It is also in our legitimate interest to enable our service providers to perform certain activities on our behalf. We consider this use to be proportionate and will not be prejudicial or detrimental to you.</td>
                    </tr>
                    <tr>
                        <td>To administer our Website including troubleshooting, data analysis, testing, research, statistical and survey purposes; and<br><br>
                        To improve our Website to ensure that consent is presented in the most effective manner for you and your computer, device or other item of hardware through which you access the Website.</td>
                        <td>For all these categories, it is in our legitimate interest to continually monitor and improve our services and your experience of the Website and to ensure network security. We consider this use to be proportionate and will not be prejudicial or detrimental to you.</td>
                    </tr>
                    <tr>
                        <td>To keep our Website safe and secure and to prevent detect fraud and abuse; and<br>
                        To comply with our legal obligations, policies, and procedures.<br>
                        We may process your personal data to respond:<br>
                            <ul>
                                <li>To governmental requests or legal process (for example, a court order, search warrant or subpoena);</li>
                                <li>To other circumstances in which we have a good faith belief that a crime has been or is being committed by a user;</li>
                                <li>To an emergency that poses a threat to the safety of you or another person;</li>
                                <li>When necessary to protect our property;</li>
                                <li>In connection with a substantial corporate transaction, such as the sale of our business, a divestiture, merger, consolidation, asset sale or in the unlikely event of bankruptcy.</li>
                            </ul>
                        </td>
                        <td>We conduct this processing to comply with our legal obligations and to protect the public interest.</td>
                    </tr>
                    <tr>
                        <td>To process otherwise for internal administrative and analytics purposes.</td>
                        <td>It is in our legitimate interest to process your personal data for internal administrative or analytics purposes. We consider this use to be proportionate and will not be prejudicial or detrimental to you.</td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card>
        <v-card flat class="pa-6">
            <p>Note that we may process your personal information for more than one lawful purpose depending on the specific business purpose for which we are using your information. Please contact us (using the contact details above) if you need details about the specific legal ground we are relying on to process your personal information.</p>
        </v-card>


        <v-card flat class="pa-6">
            <div class="text-h5 mt-3 mb-2 font-weight-bold">
                C. How Long We Keep Personal Information
            </div>
            <p>
                We will only retain your personal information in identifiable form for as long as needed for the purposes for which it is gathered and processed. In determining retention periods, we consider the amount, nature, and sensitivity of the personal information, the potential risk of harm from unauthorized use or disclosure of your personal information, the purposes for which we process your personal information and whether we can achieve those purposes through other means, and the applicable legal requirements. When we no longer need personal information, we either anonymize, securely delete, or destroy it.
            </p>
        </v-card>


        <v-card flat class="pa-6">
            <div class="text-h5 mt-3 mb-2 font-weight-bold">
                D. Your GDPR Rights
            </div>
            <p>
                We respect the rights that you have in relation to your personal information, and we will respond to requests for information and, where applicable, will correct, amend, or delete your personal information.
            </p>
            <p>
                <strong>Access to personal information.</strong> We will give you access to your personal information upon written request, subject to any relevant legal requirements and exemptions, including identity verification procedures. Before providing information to you, we will ask for proof of identity and sufficient information about your interaction with us so that we can locate any relevant data. Except where it is not permissible under applicable law, we may also charge you a fee for providing you with a copy of your data.
            </p>
            <p>
                <strong>Correction and deletion.</strong> You have the right to correct or amend your personal information if it is inaccurate or requires updating. You have the right to request deletion of your personal information; however, this is not always possible due to legal requirements and other obligations and factors.
            </p>
            <p>
                <strong>Data portability.</strong> You may have the right to (i) obtain personal information concerning you, which you have provided to Unsub, and (ii) transmit such information to another data controller. The information may be transmitted directly from Unsub to the other data controller where feasible.
            </p>
            <p>
                <strong>Direct marketing.</strong> We may send you marketing communications as set out above. Where your personal information is processed for direct marketing purposes, you have the right to object at any time to such processing, which includes profiling to the extent that it is related to such direct marketing. All of Unsub’s direct marketing communications include an “unsubscribe” link to enable you to easily opt-out of future communications.
            </p>
            <p>
                <strong>Withdrawal of consent.</strong> Where Unsub is processing your personal information on the basis that you have consented to such processing, you have the right to withdraw that consent at any time. Once we have received written notification that you have withdrawn your consent, we will no longer process your information for the purpose or purposes you originally agreed to, unless we have another legitimate basis for doing so in law.
            </p>
            <p>
                <strong>Objection to and/or restriction of processing of personal information.</strong> You have the right to: (i) restrict the processing of your personal information in certain circumstances (e.g., where you contest the accuracy of your personal information); and (ii) object to certain types of processing of personal information.
            </p>
            <p>
                <strong>Lodging a complaint.</strong> If you are not satisfied with how Unsub manages your personal information, you have the right to lodge a complaint to a data protection regulator. A list of National Data Protection Authorities (“DPA”) can be found here: <a href="https://ec.europa.eu/newsroom/article29/items/612080">https://ec.europa.eu/newsroom/article29/items/612080</a>. Before filing a complaint with a DPA, we ask that you contact us to provide us with an opportunity to resolve your complaint.
            </p>
        </v-card>


        <v-card flat class="pa-6">
            <div class="text-h5 mt-3 mb-2 font-weight-bold">
                E. Cookies
            </div>
            <p>
                Although we do not use cookies, we partner with companies that do as we explain in Section I.D. If you are in the EU, you may opt-out of certain third-party cookies that we and other websites may use for targeting through <a href="https://www.youronlinechoices.eu">https://www.youronlinechoices.eu</a> or <a href="https://youradchoices.com">https://youradchoices.com</a>. Opting out of one or more ad networks only means that those particular members no longer will deliver targeted content or ads to you. It does not mean you will stop receiving any targeted content or ads on our platforms or other third-party websites. If your browser is configured to reject cookies when you visit one of the above referenced opt-out pages, and you later erase your cookies, use a different computer or change web browsers, your preference may no longer be active. Since all of these cookies are managed by third parties, you should refer to the third parties' own website privacy notifications and policies for further information.
            </p>
        </v-card>
        <v-card flat class="pa-6">
            <div class="text-h6">
                Can I withdraw my consent?
            </div>
            <p>
                If you wish to withdraw your consent at any time, you will need to delete your cookies using your internet browser settings, and disable cookies. Please note that disabling cookies will affect the functionality of our platforms, and may prevent you from being able to access certain features on our platforms.
            </p>
        </v-card>


        <v-card flat class="pa-6">
            <div class="text-h5 mt-3 mb-2 font-weight-bold">
                F. Data Transfer
            </div>
            <p>
                Unsub is a global organization, and your personal information may be stored and processed outside of your home country. We take steps to ensure that the information we collect is processed according to this Privacy Statement and the requirements of applicable law wherever the data is located.
            </p>
            <p>
                Unsub has networks, databases, servers, systems, support, help desks, and offices located outside the EU.
            </p>
            <p>
                We use service providers located around the world, including outside the EU to serve the needs of our business, workforce, and customers. We take appropriate steps to ensure that personal information is processed, secured, and transferred according to applicable law. In some cases, we may need to disclose or transfer your personal information within Unsub or to third parties in areas outside of your home country (including areas outside the EU).
            </p>
            <p>
                We may transfer the personal information we collect about you to recipients in countries other than the country in which the information originally was collected. Those countries may not have the same data protection laws as the country in which you initially provided the information. When we transfer your information to recipients in other countries (such as the U.S.), we will protect that information as described in this Privacy Statement and will comply with applicable legal requirements providing adequate protection for the transfer of personal information to recipients in countries other than the one in which you provided the information.
            </p>
        </v-card>


        <div class="text-h4 pt-4 pb-2 font-weight-bold">
            III. CCPA Notices and Rights
        </div>
        <p>
            This section only applies to California residents. Where we collect, process, use, or disclose personal information that is subject to the CCPA, we are committed to processing your personal information in a transparent and fair manner and in compliance with the CCPA. This section describes the rights California residents have in relation to your personal information and provides the notices required by the CCPA.
        </p>
        <v-card flat class="pa-6">
            <div class="text-h5 mt-3 mb-2 font-weight-bold">
                A. Your CCPA Rights
            </div>
            <p>
                The CCPA grants California residents the rights described below.
            </p>
            <p>
                <strong>Right to Know General Collection and Use of Personal Information (Access Request).</strong> Under the CCPA, California residents have the right to request that Unsub disclose what information we have collected, used, disclosed, or sold over the past 12 months. Once we receive and confirm your verifiable consumer request for such information, we will disclose to you, based on your specific request:
            </p>
            <ul>
                <li>
                    The categories of personal information we collected about you over the past 12 months;
                </li>
                <li>
                    The categories of sources from which the personal information is collected over the past 12 months;
                </li>
                <li>
                    The business or commercial purpose for collecting or selling that personal information over the past 12 months;
                </li>
                <li>
                    The categories of third parties with whom we shared your personal information over the past 12 months;
                </li>
                <li>
                    If we disclosed your personal information for a business purpose, the personal information categories that each category of recipients obtained; and/or
                </li>
                <li>
                    If we sold your personal information for a business purpose, the personal information categories that each category of recipients purchased.
                </li>
            </ul>
            <p class="pt-6">
                <strong>Right to Know Specific Pieces of Personal Information (Data Portability).</strong> Upon your verified request for such information, we will disclose to you certain specific pieces of personal information we have collected about you over the past 12 months.
            </p>
            <p>
                <strong>Right to Request Deletion.</strong> You have the right to request that we delete any of your personal information that we have collected from you and retained, subject to certain exceptions. Once we receive and confirm your verifiable consumer request, we will delete your personal information from our records, and direct our service providers to do the same, unless an exception applies.
            </p>
            <p>
                Some of the exceptions that would allow us to deny a request for deletion include if the information is necessary for us or our service provider(s) to do the following:
            </p>
            <ul>
                <li>Complete the transaction for which we collected the personal information, provide a good or service that you requested, take actions reasonably anticipated within the context of our ongoing business relationship with you, or otherwise perform a contract we have with you.</li>
                <li>Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or prosecute those responsible for such activities.</li>
                <li>Debug products to identify and repair errors that impair existing intended functionality.
                <li>Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or exercise another right provided for by law.</li>
                <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).</li>
                <li>Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest that adheres to all other applicable ethics and privacy laws, when the information's deletion may likely render impossible or seriously impair the research's achievement, if you previously provided informed consent.</li>
                <li>Enable solely internal uses that are reasonably aligned with consumer expectations based on your relationship with us.</li>
                <li>Comply with a legal obligation.</li>
                <li>Make other internal and lawful uses of that information that are compatible with the context in which you provided it.</li>
            </ul>
            <p class="pt-6">
                <strong>Right to Opt-Out of Sale of Personal Information.</strong> You have the right to direct businesses that sell personal information to not sell your personal information (the "right to opt-out"). Unsub does not sell any personal information, including the personal information of California residents who are under 16 years of age with or without affirmative authorization.
            </p>
            <p class="pt-6">
                <strong>Right to Non-Discrimination.</strong> You have the right not to be discriminated against for having exercised the rights established by the CCPA. We will not discriminate against you for exercising any of your CCPA rights. Unless permitted by the CCPA, we will not:
            </p>
            <ul>
                <li>
                    Deny you goods or services.
                </li>
                <li>
                    Charge you different prices or rates for goods or services, including through granting discounts or other benefits or imposing penalties.
                </li>
                <li>
                    Provide you a different level or quality of goods or services.
                </li>
                <li>
                    Suggest that you may receive a different price or rate for goods or services or a different level or quality of goods or services.
                </li>
            </ul>
        </v-card>


        <v-card flat class="pa-6">
            <div class="text-h5 mt-3 mb-2 font-weight-bold">
                B. Exercising Your CCPA Rights
            </div>
            <p>
                This section of our Privacy Statement explains how a California resident can exercise their rights.
            </p>
            <p>
                To exercise your right to know or your right to deletion, you or your authorized agent may submit a verifiable consumer request in one of the following ways:
            </p>
            <ul>
                <li>
                    Call us toll free at 707-889-3263.
                </li>
                <li>
                    Fill out this <a href="/privacy-form">form</a>.
                </li>
            </ul>
            <p class="pt-6">
                Upon receipt of your request to know or request for deletion, we will confirm receipt within 10 days. If we are able to verify your request, we will make our best effort to respond within forty-five (45) days of our receipt of your request. If we require more time (up to 45 additional days), we will inform you of the reason and extension period in writing.
            </p>
        </v-card>
        <v-card flat class="pa-6">
            <div class="text-h6">
                Additional Information About Requests to Know
            </div>
            <p>
                When responding to a request to know, we will not disclose information to you if we cannot verify your identity.
            </p>
            <p>
                The CCPA allows a California resident to make a verifiable consumer request to know only twice within a 12-month period. Additionally, the verifiable consumer request must:
            </p>
            <ol>
                <li>
                    Provide sufficient information that allows us to reasonably verify you are: (i) the person about whom we collected personal information, or (ii) an authorized representative.
                </li>
                <li>
                    Describe your request with sufficient detail that allows us to properly understand, evaluate, and respond to it.
                </li>
            </ol>
        </v-card>
        <v-card flat class="pa-6">
            <div class="text-h6">
                Additional Information About Requests to Delete
            </div>
            <p>
                When responding to a request for deletion, we will specify the manner in which we have deleted your personal information, in accordance with the CCPA. We will not delete your information if we cannot verify your identity.
            </p>
        </v-card>


        <v-card flat class="pa-6">
            <div class="text-h5 mt-3 mb-2 font-weight-bold">
                C. Designating An Authorized Agent
            </div>
            <p>
                Only you as a California resident, or a person registered with the California Secretary of State that you authorize to act on your behalf (Authorized Agent), may make a verifiable request to know or a request for deletion. If you are using an Authorized Agent to exercise your CCPA rights, we require the Authorized Agent to provide us with written confirmation that you have authorized them to act on your behalf. We may also verify the identity of the Authorized Agent.
            </p>
        </v-card>


        <v-card flat class="pa-6">
            <div class="text-h5 mt-3 mb-2 font-weight-bold">
                D. How We Verify California Residents’ Requests To Know And Requests For Deletion
            </div>
            <p>
                We will not respond to requests to know or requests for deletion unless we can verify your identity to a reasonable degree of certainty. To verify your identity, when feasible, we will use information about you that we already have; however, on occasion we may need to request additional information, which we will use only for the purposes of verification. We may also use a third-party identify verification service.
            </p>
            <p>
                The information we need to verify your request will depend on the type of request, the sensitivity of the personal information requested, and/or the risk of harm to you. Upon receipt of your request, we will notify you if we need additional information from you to verify your request.
            </p>
        </v-card>


        <v-card flat class="pa-6">
            <div class="text-h5 mt-3 mb-2 font-weight-bold">
                E. Notice At Collection Of Personal Information (for California Residents)
            </div>
            <p>
                This <strong>Notice at Collection of Personal Information (“Notice at Collection”)</strong> is part of Unsub's Privacy Statement and includes details about the personal information we collect from California residents and the purposes for which that personal information will be used. This Notice at Collection applies solely to California residents ("California consumers" or "you"). We adopt this Notice at Collection in accordance with the California Consumer Privacy Act of 2018 (“CCPA”) and any terms defined in the CCPA have the same meaning when used in this Notice at Collection.
            </p>
        </v-card>
        <v-card flat class="pa-6">
            <div class="text-h6">
                Personal Information We Collect About You
            </div>
            <p>
                Unsub collects personal information, which the CCPA defines as information that identifies, relates to, describes, is reasonably capable of being associated with, or could reasonably be linked with a particular California consumer or household. In particular, we may collect the following <strong>categories of personal information</strong> from or about you:
            </p>
            <ul>
                <li><strong>Identity Data:</strong> includes first name, maiden name, last name, username or similar identifier, marital status, title, job title, company name, date of birth, and gender.</li>
                <li><strong>Contact Data:</strong> includes billing address, delivery address, email address, and telephone numbers.</li>
                <li><strong>Transaction Data:</strong> includes details about payments to and from you and other details of products and services you have purchased from us.</li>
                <li><strong>Technical Data:</strong> includes internet protocol (“IP”) address, cookies, java script, web beacons, clear gifs, HTTP headers, your login data, browser type and version, time zone setting and location, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access Unsub.</li>
                <li><strong>Profile Data:</strong> includes your username and password, purchases or orders made by you, your interests, preferences, feedback, survey responses, and any unsolicited personal information not otherwise covered by this Privacy Statement that you choose to submit in an enquiry or webchat, on a blog forum or otherwise on our platforms or in person to a Unsub representative at an event.</li>
                <li><strong>Usage Data:</strong> includes information about how you use Unsub, and/or our products and services.</li>
                <li><strong>Marketing and Communications Data:</strong> includes your communication preferences in receiving marketing from us and third parties.</li>
                <li><strong>Social Media Application Data:</strong> includes, without limitation, your public profile, social media relationships, listed work and education history, listed hometown and current city, profile photos, personal description, and “likes.”</li>
                <li><strong>Sensitive Personal Information:</strong> Occasionally we may collect sensitive personal information that relates to racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, genetic data, biometric data, data concerning health, or data concerning a natural person's sex life or sexual orientation. For example, we may collect certain information made available through Social Media Applications. This information may contain sensitive personal information if you have made that information available on your Social Media profile. In addition, the information that you submit in blog forums (or otherwise) on Unsub or when interacting with our Covered Products and Services may include sensitive personal information.</li>
            </ul>
        </v-card>
        <v-card flat class="pa-6">
            <div class="text-h6">
                How We Use Your Personal Information
            </div>
            <p>
                We will only use your personal information in a manner that is consistent with applicable laws. Most commonly, we will use the categories of personal information identified above for the following business purposes:
            </p>
            <ul>
                <li>For the provision of our Covered Products and Services or the operation of our business or a third party’s business. Personal information may be made available to Unsub businesses around the world if necessary for the provision of our Covered Products and Services, account administration, sales and marketing, customer and technical support, and business and product development, for instance. All of our employees and contractors are required to follow our data privacy and security policies when handling personal information.</li>
                <li>Where we need to perform a contract that we have entered into with you or in order to take steps at your request prior to entering into a contract.</li>
                <li>To protect the security and integrity of our business, or our Covered Products and Services.</li>
                <li>Where we need to protect your interests (or someone else's interests).</li>
                <li>Where it is needed in the public interest or for official purposes.</li>
                <li>In connection with the sale, merger, acquisition, or other reorganization of our business. Such a transaction may involve the disclosure of personal information to prospective or actual purchasers, or the receipt of it from sellers. It is Unsub's practice to seek appropriate protection for information in these types of transactions.</li>
                <li>For compliance with legal obligations and protection of Unsub and others. Specifically, we may be required to use and retain personal information for legal and compliance reasons, such as the prevention, detection, or investigation of a crime, loss prevention, or fraud. We may also use personal information to meet our internal and external audit requirements, and as we otherwise believe to be necessary or appropriate: (i) under applicable law, which may include laws outside your country of residence; (ii) to respond to requests from courts, law enforcement agencies, regulatory agencies, and other public and government authorities, which may include such authorities outside your country of residence; (iii) to enforce or apply our contractual rights; and (iv) to otherwise protect our rights, privacy, safety, or property, or those of other persons.</li>
                <li>For marketing purposes. Specifically, we may use your personal information (including cookies – for more information on how we use cookies, please see Section I.D.) to form a view on what we think you may want or need, or what may be of interest to you. This is how we decide which products, services, and offers may be relevant for you (we call this marketing).</li>
                <ul>
                    <li>Where it is necessary for the purposes of our legitimate interests or those of a third party (e.g., for the provision of our services or products, the operation of our business or a third party’s business, or the marketing of products or services by us to you in circumstances where you have already bought or subscribed for similar Unsub products or services) and your interests and fundamental rights and freedoms do not override our legitimate interests.</li>
                    <li>You may receive marketing communications from us if you have purchased products or services from us, we have identified similar products or services that may be relevant for you and, in each case, you have not opted out of receiving that marketing.</li>
                    <li>We will get your express opt-in consent before we market products or services to you that are not similar to products or services you have purchased from us before, or if we share your personal information with any company outside the Unsub group of companies for marketing purposes.</li>
                    <li>You can ask us or third parties to stop sending you marketing messages at any time. If you receive a marketing communication from us, the communication will contain an option to opt out of further communications. If you receive a marketing communication from one of our business partners, or from social media providers, you should opt out with that entity directly. You can also contact us at any time to opt out using the details given at the end of this Privacy Statement.</li>
                </ul>
                <li>We may disclose your personal information to entities other than Unsub for business purposes. When we disclose personal information, we do so in accordance with our data privacy and security requirements. Please see Section I.C. for more information about these types of disclosures.</li>
            </ul>
            <p class="pt-12">
                We will only use your personal information for the purposes for which we collected it, including where we reasonably consider that we need to use it for a reason that is compatible with the original purpose. If we need to use your personal information for an unrelated purpose, we will notify you as required by law.
            </p>
            <p>
                Please note that we may process your personal information without your knowledge or consent where this is required or permitted by law.
            </p>
        </v-card>


    </v-container>
</template>

<script>
    export default {
        name: "Privacy",
        metaInfo() {
            return {
                title: `Privacy`
            }
        },
    }
</script>

<style scoped>

</style>

import { render, staticRenderFns } from "./PublisherWarningIcon.vue?vue&type=template&id=51c2fe63&scoped=true&"
import script from "./PublisherWarningIcon.vue?vue&type=script&lang=js&"
export * from "./PublisherWarningIcon.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "51c2fe63",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})

<template>
    <v-container class="mt-3">
        <v-card flat class="pa-6">
            <div class="text-h3 mb-6 font-weight-bold">
                Contact us
            </div>
            <p>
                If you'd like to get in touch, we'd love to hear from you! You can use the little blue message icon on
                the bottom right of your screen, or drop us an email at <a href="mailto:support@unsub.org">support@unsub.org</a>
            </p>

            <p>
                We're on the Twitters at <a href="https://twitter.com/unsub_org">@unsub_org</a>.
            </p>
            <p>
                We also have two mailing lists: <a href="https://groups.google.com/g/unsub-discuss">Unsub-Discuss</a> for conversations with us and other Unsub users, and
                <a href="https://groups.google.com/g/unsub-announce">Unsub-Announce</a> for (you guessed it) announcements.
            </p>
            <p>
                Hope to hear from you soon!
            </p>


        </v-card>


    </v-container>
</template>

<script>
    export default {
        name: "Press",
        metaInfo() {
            return {
                title: `Contact`
            }
        },
    }
</script>

<style scoped>

</style>

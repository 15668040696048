<template>
    <v-container class="mt-3">
        <v-card flat class="pa-6">
            <div class="text-h3 font-weight-bold">
                Our Team
            </div>
            <div class="mt-6">
                <p>
                    Unsub is a project of <a href="https://ourresearch.org">OurResearch,</a> a
                    nonprofit
                    building tools to help make scholarly research more open, connected, and reusable. We maintain a small full-time <a href="https://ourresearch.org/about" target="_blank">team</a>, which helps us stay agile and sustainable.
                </p>
                <p>
                    Many people have contributed to and continue to be involved in Unsub (Heather, Jason, Richard), but Scott is currently doing most of the work. See our <a href="https://ourresearch.org/about" target="_blank">team</a> page for bios.
                </p>
                <p>
                    If you'd like to get in touch, we'd love to hear from you! You can use the little blue message icon on the bottom right of your screen, or drop us an email at <a href="mailto:support@unsub.org">support@unsub.org</a>

                </p>
            </div>
        </v-card>


    </v-container>
</template>

<script>
    export default {
        name: "Team",
        metaInfo() {
            return {
                title: `Team`
            }
        },
    }
</script>

<style scoped>

</style>
